
















































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import AreaSelect from '@/components/area-select.vue'
import GoodsSelect from '@/components/goods-select/index.vue'
import DatePicker from '@/components/date-picker.vue'
import {
    apiTeamAdd,
    apiTeamDetail,
    apiTeamEdit
} from '@/api/marketing/combination'
@Component({
    components: {
        AreaSelect,
        GoodsSelect,
        DatePicker
    }
})
export default class AddSupplier extends Vue {
    /** S Data **/

    id!: any //当前的ID

    disabled: any = false //是否禁用

    status: any = 0 //状态 1=未开始 2=进行中 3=已结束

    teamList: any = {
        name: '', //必选	string	拼团活动名称
        min_buy: 1, //必选	int	起购限制: 0=不限制
        max_buy: 1, //必选	int	每单限制: 0=不限制
        people_num: '', //必选	int	拼团所需人数
        is_coupon: 1, //必选	int	是否能用活动 0=否， 1=是
        is_distribution: 1, //必选	int	是否参与分销功能 0=否，1=是
        is_automatic: 1, //必选	int	是否开启虚拟自动成团
        goods: [], //必选	array	参与活动的商品，PS: 具体格式看请求示例
        explain: '', //否	string	活动说明
        effective_time: '', //必选	int	拼团失效时间, 分钟
        start_time: '', //必选	datetime	活动开始时间, 如: 2021-07-26 11:52:32
        end_time: '' //必选	datetime	活动开始时间, 如: 2021-07-26 11:52:32 不能少于开始时间
    }

    selectGoods: any = [] //商品选择的商品数据

    /** E Data **/

    @Watch('selectGoods', { deep: true })
    selectGoodsChange(val: any[]) {
        this.teamList.goods = val.map((item: any) => {
            return {
                goods_id: item.id,
                items: item.item.map((sitem: any) => ({
                    item_id: sitem.id,
                    team_price: sitem.team_price
                }))
            }
        })
    }

    checkGoods() {
        const goods = this.teamList.goods
        if (!goods.length) {
            this.$message.error('请选择拼团商品')
            return false
        }
        for (let i = 0; i < goods.length; i++) {
            for (let j = 0; j < goods[i].items.length; j++) {
                if (!goods[i].items[j].team_price) {
                    this.$message.error(`请输入商品拼团价`)
                    return false
                }
            }
        }
        return true
    }

    /** S Method **/

    // 起购不允许为空
    buyBuy(event: any) {
        if (event.detail === '') {
            this.teamList.min_buy = 0
        }
    }

    // 起购不允许为空
    buyBuy2(event: any) {
        if (event === '') {
            this.teamList.max_buy = 0
        }
    }

    // 提交
    submit() {
        if (!this.checkGoods()) {
            return
        }
        // // 新增还是删除
        if (this.id) {
            this.teamEdit()
        } else {
            this.teamAdd()
        }
    }

    // 编辑活动
    teamEdit() {
        apiTeamEdit({ ...this.teamList })
            .then(res => {
                setTimeout(() => this.$router.go(-1), 500)
            })
            .catch(() => {
                this.$message.error('修改失败!')
            })
    }

    // 添加活动
    teamAdd() {
        apiTeamAdd({ ...this.teamList })
            .then(res => {
                setTimeout(() => this.$router.go(-1), 500)
            })
            .catch(() => {
                this.$message.error('添加失败!')
            })
    }

    // 获取活动详情
    getteamDetail() {
        apiTeamDetail({ id: this.id }).then((res: any) => {
            this.teamList = res
            this.selectGoods = res.goods
        })
    }

    /** E Method **/

    created() {
        this.status = this.$route.query.status
        this.disabled = this.$route.query.disabled == 'true' ? true : false
        this.id = this.$route.query.id
        this.id && this.getteamDetail()
    }
}
